import React from 'react';
import Table from './Table';
import skeletonLoading from '../../assets/svgs/chat-thread.svg';
import Button from '../Button';

const ClientsTable = ({
	setOpenSideModal,
	data,
	setSelectedClient,
	handleClearFilter,
}) => {
	const columns = ['First Name', 'Last Name', 'Client ID', 'Pass Type'];
	return (
		<>
			{data?.length < 1 ? (
				<div className="emptyClients">
					<div className="noEmployee__container">
						<img src={skeletonLoading} alt="loading" />
						<h4>Client not Found</h4>
						<p>
							No matching client was found. Please verify the information and
							search again.
						</p>
						<Button
							variant={'primary'}
							size={'md'}
							onClick={handleClearFilter}
							block={true}
							className={'mt-24'}
						>
							Show all clients
						</Button>
					</div>
				</div>
			) : (
				<Table columns={columns} blueHeader={true}>
					<>
						{data?.map((row) => (
							<tr
								className="pointer grey-bg"
								onClick={() => {
									setOpenSideModal(true);
									setSelectedClient(row);
								}}
								key={row?.id}
							>
								<td className="plan-table ">{row?.firstName}</td>
								<td className="plan-table duration">{row?.lastName}</td>
								<td className="plan-table">{row?.clientId}</td>
								<td className="plan-table">{row?.passName}</td>
							</tr>
						))}
					</>
				</Table>
			)}
		</>
	);
};

export default ClientsTable;
