import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import {
	addUserToLocalStorage,
	getUserFromLocalStorage,
	removeUserFromLocalStorage,
} from '../../utils/localstorage';
import { toast } from 'react-hot-toast';

const initialState = {
	isLoading: false,
	user: null,
	errMsg: null,
	userDetails: getUserFromLocalStorage(),
	resetPassword: false,
	businessDetails: null,
	updateLoading: false,
	logOutLoading: false,
	signedUrl: null,
	uploadingFile: false,
	uploadFileStatus: null,
};

const notify = (message) => toast.error(message);
const notifySuccess = (message) => toast.success(message);

export const registerUser = createAsyncThunk(
	'user/registerUser',
	async (user, thunkAPI) => {
		localStorage.setItem('businessMail', JSON.stringify(user.businessEmail));
		try {
			const resp = await customFetch.post('business/auth/signup', user);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);
export const loginUser = createAsyncThunk(
	'user/loginUser',
	async (user, thunkAPI) => {
		try {
			const resp = await customFetch.post('business/auth/login', user);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

export const resendUserVerification = createAsyncThunk(
	'user/resendUserVerification',
	async (user, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/auth/verify/email/resend',
				user
			);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

export const forgotPassword = createAsyncThunk(
	'user/forgotPassword',
	async (user, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/auth/notify/password/reset?email&resetLink',
				user
			);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

export const getBusinessDetails = createAsyncThunk(
	'business/user/getBusinessDetails',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get('business');
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

export const updateUserDetails = createAsyncThunk(
	'user/updateUserDetails',
	async (user, thunkAPI) => {
		try {
			const resp = await customFetch.put('business/update-details', user);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

export const updateUserPassword = createAsyncThunk(
	'user/updateUserPassword',
	async (user, thunkAPI) => {
		try {
			const resp = await customFetch.put('business/password', user);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);
export const fetchSignedUrl = createAsyncThunk(
	'user/fetchSignedUrl',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get('business/signed-url/pdf/cac');
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);
export const submitCaCfile = createAsyncThunk(
	'user/submitCaCfile',
	async (status, thunkAPI) => {
		try {
			const resp = await customFetch.post('business/kycs/submit', status);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);
export const logoutCurrnentUser = createAsyncThunk(
	'user/logOutUser',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get('business/logout');
			thunkAPI.dispatch(logoutUser());
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logoutUser: (state, { payload }) => {
			state.userDetails = null;
			removeUserFromLocalStorage();
			window.location.href = '/auth/login';
			localStorage.removeItem('selectedBenefits');
			localStorage.removeItem('plansSelected');
			if (payload) {
				toast.success(payload);
			}
		},
		clearUser: (state) => {
			state.user = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(registerUser.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.user = payload;
			})
			.addCase(registerUser.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.errMsg = payload;
				notify(payload?.message);
			})
			.addCase(loginUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(loginUser.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.userDetails = payload;
				window.location.href = '/plan';
				localStorage.removeItem('selectedBenefits');
				localStorage.removeItem('plansSelected');
				addUserToLocalStorage(payload);
			})
			.addCase(loginUser.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.errMsg = payload;
				notify(payload?.message);
			})
			.addCase(logoutCurrnentUser.pending, (state) => {
				state.logOutLoading = true;
			})
			.addCase(logoutCurrnentUser.fulfilled, (state) => {
				state.logOutLoading = false;
			})
			.addCase(logoutCurrnentUser.rejected, (state) => {
				state.logOutLoading = false;
				// notify(payload?.message);
			})
			.addCase(resendUserVerification.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(resendUserVerification.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				notifySuccess(payload?.message);
			})
			.addCase(resendUserVerification.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload?.message);
			})
			.addCase(fetchSignedUrl.pending, () => {
				// state.isLoading = true;
			})
			.addCase(fetchSignedUrl.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.signedUrl = payload;
			})
			.addCase(fetchSignedUrl.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload?.message);
			})
			.addCase(submitCaCfile.pending, (state) => {
				state.uploadingFile = true;
			})
			.addCase(submitCaCfile.fulfilled, (state) => {
				state.uploadingFile = false;
			})
			.addCase(submitCaCfile.rejected, (state, { payload }) => {
				state.uploadingFile = false;
				notify(payload?.message);
			})
			.addCase(forgotPassword.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(forgotPassword.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.resetPassword = true;
				notifySuccess(payload?.message);
			})
			.addCase(forgotPassword.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload?.message);
			})
			.addCase(getBusinessDetails.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getBusinessDetails.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.businessDetails = payload;
			})
			.addCase(getBusinessDetails.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload);
			})
			.addCase(updateUserDetails.pending, (state) => {
				state.updateLoading = true;
			})
			.addCase(updateUserDetails.fulfilled, (state, { payload }) => {
				state.updateLoading = false;
				notifySuccess(payload?.message);
			})
			.addCase(updateUserDetails.rejected, (state, { payload }) => {
				state.updateLoading = false;
				notify(payload?.message);
			})
			.addCase(updateUserPassword.pending, (state) => {
				state.updateLoading = true;
			})
			.addCase(updateUserPassword.fulfilled, (state, { payload }) => {
				state.updateLoading = false;
				notifySuccess(payload?.message);
			})
			.addCase(updateUserPassword.rejected, (state, { payload }) => {
				state.updateLoading = false;
				notify(payload?.message);
			});
	},
});

export const { logoutUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
