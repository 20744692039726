export const countries = [
	{
		code: 'NG',
		name: 'Nigeria',
		flag: '🇳🇬',
		dialCode: '+234',
		currency: 'NGN',
	},
	{
		code: 'AF',
		name: 'Afghanistan',
		flag: '🇦🇫',
		dialCode: '+93',
		currency: 'AFN',
	},
	{
		code: 'AL',
		name: 'Albania',
		flag: '🇦🇱',
		dialCode: '+355',
		currency: 'ALL',
	},
	{
		code: 'DZ',
		name: 'Algeria',
		flag: '🇩🇿',
		dialCode: '+213',
		currency: 'DZD',
	},
	{
		code: 'AS',
		name: 'American Samoa',
		flag: '🇦🇸',
		dialCode: '+1684',
		currency: 'USD',
	},
	{
		code: 'AD',
		name: 'Andorra',
		flag: '🇦🇩',
		dialCode: '+376',
		currency: 'EUR',
	},
	{ code: 'AO', name: 'Angola', flag: '🇦🇴', dialCode: '+244', currency: 'AOA' },
	{
		code: 'AI',
		name: 'Anguilla',
		flag: '🇦🇮',
		dialCode: '+1264',
		currency: 'XCD',
	},
	{
		code: 'AG',
		name: 'Antigua and Barbuda',
		flag: '🇦🇬',
		dialCode: '+1268',
		currency: 'XCD',
	},
	{
		code: 'AR',
		name: 'Argentina',
		flag: '🇦🇷',
		dialCode: '+54',
		currency: 'ARS',
	},
	{
		code: 'AM',
		name: 'Armenia',
		flag: '🇦🇲',
		dialCode: '+374',
		currency: 'AMD',
	},
	{ code: 'AW', name: 'Aruba', flag: '🇦🇼', dialCode: '+297', currency: 'AWG' },
	{
		code: 'AU',
		name: 'Australia',
		flag: '🇦🇺',
		dialCode: '+61',
		currency: 'AUD',
	},
	{ code: 'AT', name: 'Austria', flag: '🇦🇹', dialCode: '+43', currency: 'EUR' },
	{
		code: 'AZ',
		name: 'Azerbaijan',
		flag: '🇦🇿',
		dialCode: '+994',
		currency: 'AZN',
	},
	{
		code: 'BS',
		name: 'Bahamas',
		flag: '🇧🇸',
		dialCode: '+1242',
		currency: 'BSD',
	},
	{
		code: 'BH',
		name: 'Bahrain',
		flag: '🇧🇭',
		dialCode: '+973',
		currency: 'BHD',
	},
	{
		code: 'BD',
		name: 'Bangladesh',
		flag: '🇧🇩',
		dialCode: '+880',
		currency: 'BDT',
	},
	{
		code: 'BB',
		name: 'Barbados',
		flag: '🇧🇧',
		dialCode: '+1246',
		currency: 'BBD',
	},
	{
		code: 'BY',
		name: 'Belarus',
		flag: '🇧🇾',
		dialCode: '+375',
		currency: 'BYN',
	},
	{ code: 'BE', name: 'Belgium', flag: '🇧🇪', dialCode: '+32', currency: 'EUR' },
	{ code: 'BZ', name: 'Belize', flag: '🇧🇿', dialCode: '+501', currency: 'BZD' },
	{ code: 'BJ', name: 'Benin', flag: '🇧🇯', dialCode: '+229', currency: 'XOF' },
	{
		code: 'BM',
		name: 'Bermuda',
		flag: '🇧🇲',
		dialCode: '+1441',
		currency: 'BMD',
	},
	{ code: 'BT', name: 'Bhutan', flag: '🇧🇹', dialCode: '+975', currency: 'BTN' },
	{
		code: 'BO',
		name: 'Bolivia',
		flag: '🇧🇴',
		dialCode: '+591',
		currency: 'BOB',
	},
	{
		code: 'BA',
		name: 'Bosnia and Herzegovina',
		flag: '🇧🇦',
		dialCode: '+387',
		currency: 'BAM',
	},
	{
		code: 'BW',
		name: 'Botswana',
		flag: '🇧🇼',
		dialCode: '+267',
		currency: 'BWP',
	},
	{ code: 'BR', name: 'Brazil', flag: '🇧🇷', dialCode: '+55', currency: 'BRL' },
	{
		code: 'IO',
		name: 'British Indian Ocean Territory',
		flag: '🇮🇴',
		dialCode: '+246',
		currency: 'USD',
	},
	{ code: 'BN', name: 'Brunei', flag: '🇧🇳', dialCode: '+673', currency: 'BND' },
	{
		code: 'BG',
		name: 'Bulgaria',
		flag: '🇧🇬',
		dialCode: '+359',
		currency: 'BGN',
	},
	{
		code: 'BF',
		name: 'Burkina Faso',
		flag: '🇧🇫',
		dialCode: '+226',
		currency: 'XOF',
	},
	{
		code: 'BI',
		name: 'Burundi',
		flag: '🇧🇮',
		dialCode: '+257',
		currency: 'BIF',
	},
	{
		code: 'KH',
		name: 'Cambodia',
		flag: '🇰🇭',
		dialCode: '+855',
		currency: 'KHR',
	},
	{
		code: 'CM',
		name: 'Cameroon',
		flag: '🇨🇲',
		dialCode: '+237',
		currency: 'XAF',
	},
	{ code: 'CA', name: 'Canada', flag: '🇨🇦', dialCode: '+1', currency: 'CAD' },
	{
		code: 'CV',
		name: 'Cape Verde',
		flag: '🇨🇻',
		dialCode: '+238',
		currency: 'CVE',
	},
	{
		code: 'KY',
		name: 'Cayman Islands',
		flag: '🇰🇾',
		dialCode: '+1345',
		currency: 'KYD',
	},
	{
		code: 'CF',
		name: 'Central African Republic',
		flag: '🇨🇫',
		dialCode: '+236',
		currency: 'XAF',
	},
	{ code: 'TD', name: 'Chad', flag: '🇹🇩', dialCode: '+235', currency: 'XAF' },
	{ code: 'CL', name: 'Chile', flag: '🇨🇱', dialCode: '+56', currency: 'CLP' },
	{ code: 'CN', name: 'China', flag: '🇨🇳', dialCode: '+86', currency: 'CNY' },
	{
		code: 'CO',
		name: 'Colombia',
		flag: '🇨🇴',
		dialCode: '+57',
		currency: 'COP',
	},
	{
		code: 'KM',
		name: 'Comoros',
		flag: '🇰🇲',
		dialCode: '+269',
		currency: 'KMF',
	},
	{ code: 'CG', name: 'Congo', flag: '🇨🇬', dialCode: '+242', currency: 'XAF' },
	{
		code: 'CD',
		name: 'Congo, Democratic Republic',
		flag: '🇨🇩',
		dialCode: '+243',
		currency: 'CDF',
	},
	{
		code: 'CK',
		name: 'Cook Islands',
		flag: '🇨🇰',
		dialCode: '+682',
		currency: 'NZD',
	},
	{
		code: 'CR',
		name: 'Costa Rica',
		flag: '🇨🇷',
		dialCode: '+506',
		currency: 'CRC',
	},
	{
		code: 'HR',
		name: 'Croatia',
		flag: '🇭🇷',
		dialCode: '+385',
		currency: 'HRK',
	},
	{ code: 'CU', name: 'Cuba', flag: '🇨🇺', dialCode: '+53', currency: 'CUP' },
	{ code: 'CY', name: 'Cyprus', flag: '🇨🇾', dialCode: '+357', currency: 'EUR' },
	{
		code: 'CZ',
		name: 'Czech Republic',
		flag: '🇨🇿',
		dialCode: '+420',
		currency: 'CZK',
	},
	{ code: 'DK', name: 'Denmark', flag: '🇩🇰', dialCode: '+45', currency: 'DKK' },
	{
		code: 'DJ',
		name: 'Djibouti',
		flag: '🇩🇯',
		dialCode: '+253',
		currency: 'DJF',
	},
	{
		code: 'DM',
		name: 'Dominica',
		flag: '🇩🇲',
		dialCode: '+1767',
		currency: 'XCD',
	},
	{
		code: 'DO',
		name: 'Dominican Republic',
		flag: '🇩🇴',
		dialCode: '+1849',
		currency: 'DOP',
	},
	{
		code: 'EC',
		name: 'Ecuador',
		flag: '🇪🇨',
		dialCode: '+593',
		currency: 'USD',
	},
	{ code: 'EG', name: 'Egypt', flag: '🇪🇬', dialCode: '+20', currency: 'EGP' },
	{
		code: 'SV',
		name: 'El Salvador',
		flag: '🇸🇻',
		dialCode: '+503',
		currency: 'USD',
	},
	{
		code: 'GQ',
		name: 'Equatorial Guinea',
		flag: '🇬🇶',
		dialCode: '+240',
		currency: 'XAF',
	},
	{
		code: 'ER',
		name: 'Eritrea',
		flag: '🇪🇷',
		dialCode: '+291',
		currency: 'ERN',
	},
	{
		code: 'EE',
		name: 'Estonia',
		flag: '🇪🇪',
		dialCode: '+372',
		currency: 'EUR',
	},
	{
		code: 'ET',
		name: 'Ethiopia',
		flag: '🇪🇹',
		dialCode: '+251',
		currency: 'ETB',
	},
	{
		code: 'FK',
		name: 'Falkland Islands',
		flag: '🇫🇰',
		dialCode: '+500',
		currency: 'FKP',
	},
	{
		code: 'FO',
		name: 'Faroe Islands',
		flag: '🇫🇴',
		dialCode: '+298',
		currency: 'DKK',
	},
	{ code: 'FJ', name: 'Fiji', flag: '🇫🇯', dialCode: '+679', currency: 'FJD' },
	{
		code: 'FI',
		name: 'Finland',
		flag: '🇫🇮',
		dialCode: '+358',
		currency: 'EUR',
	},
	{ code: 'FR', name: 'France', flag: '🇫🇷', dialCode: '+33', currency: 'EUR' },
	{
		code: 'GF',
		name: 'French Guiana',
		flag: '🇬🇫',
		dialCode: '+594',
		currency: 'EUR',
	},
	{
		code: 'PF',
		name: 'French Polynesia',
		flag: '🇵🇫',
		dialCode: '+689',
		currency: 'XPF',
	},
	{ code: 'GA', name: 'Gabon', flag: '🇬🇦', dialCode: '+241', currency: 'XAF' },
	{ code: 'GM', name: 'Gambia', flag: '🇬🇲', dialCode: '+220', currency: 'GMD' },
	{
		code: 'GE',
		name: 'Georgia',
		flag: '🇬🇪',
		dialCode: '+995',
		currency: 'GEL',
	},
	{ code: 'DE', name: 'Germany', flag: '🇩🇪', dialCode: '+49', currency: 'EUR' },
	{ code: 'GH', name: 'Ghana', flag: '🇬🇭', dialCode: '+233', currency: 'GHS' },
	{
		code: 'GI',
		name: 'Gibraltar',
		flag: '🇬🇮',
		dialCode: '+350',
		currency: 'GIP',
	},
	{ code: 'GR', name: 'Greece', flag: '🇬🇷', dialCode: '+30', currency: 'EUR' },
	{
		code: 'GL',
		name: 'Greenland',
		flag: '🇬🇱',
		dialCode: '+299',
		currency: 'DKK',
	},
	{
		code: 'GD',
		name: 'Grenada',
		flag: '🇬🇩',
		dialCode: '+1473',
		currency: 'XCD',
	},
	{
		code: 'GP',
		name: 'Guadeloupe',
		flag: '🇬🇵',
		dialCode: '+590',
		currency: 'EUR',
	},
	{ code: 'GU', name: 'Guam', flag: '🇬🇺', dialCode: '+1671', currency: 'USD' },
	{
		code: 'GT',
		name: 'Guatemala',
		flag: '🇬🇹',
		dialCode: '+502',
		currency: 'GTQ',
	},
	{ code: 'GN', name: 'Guinea', flag: '🇬🇳', dialCode: '+224', currency: 'GNF' },
	{
		code: 'GW',
		name: 'Guinea-Bissau',
		flag: '🇬🇼',
		dialCode: '+245',
		currency: 'XOF',
	},
	{ code: 'GY', name: 'Guyana', flag: '🇬🇾', dialCode: '+592', currency: 'GYD' },
	{ code: 'HT', name: 'Haiti', flag: '🇭🇹', dialCode: '+509', currency: 'HTG' },
	{
		code: 'HN',
		name: 'Honduras',
		flag: '🇭🇳',
		dialCode: '+504',
		currency: 'HNL',
	},
	{
		code: 'HK',
		name: 'Hong Kong',
		flag: '🇭🇰',
		dialCode: '+852',
		currency: 'HKD',
	},
	{ code: 'HU', name: 'Hungary', flag: '🇭🇺', dialCode: '+36', currency: 'HUF' },
	{
		code: 'IS',
		name: 'Iceland',
		flag: '🇮🇸',
		dialCode: '+354',
		currency: 'ISK',
	},
	{ code: 'IN', name: 'India', flag: '🇮🇳', dialCode: '+91', currency: 'INR' },
	{
		code: 'ID',
		name: 'Indonesia',
		flag: '🇮🇩',
		dialCode: '+62',
		currency: 'IDR',
	},
	{ code: 'IR', name: 'Iran', flag: '🇮🇷', dialCode: '+98', currency: 'IRR' },
	{ code: 'IQ', name: 'Iraq', flag: '🇮🇶', dialCode: '+964', currency: 'IQD' },
	{
		code: 'IE',
		name: 'Ireland',
		flag: '🇮🇪',
		dialCode: '+353',
		currency: 'EUR',
	},
	{ code: 'IL', name: 'Israel', flag: '🇮🇱', dialCode: '+972', currency: 'ILS' },
	{ code: 'IT', name: 'Italy', flag: '🇮🇹', dialCode: '+39', currency: 'EUR' },
	{
		code: 'JM',
		name: 'Jamaica',
		flag: '🇯🇲',
		dialCode: '+1876',
		currency: 'JMD',
	},
	{ code: 'JP', name: 'Japan', flag: '🇯🇵', dialCode: '+81', currency: 'JPY' },
	{ code: 'JO', name: 'Jordan', flag: '🇯🇴', dialCode: '+962', currency: 'JOD' },
	{
		code: 'KZ',
		name: 'Kazakhstan',
		flag: '🇰🇿',
		dialCode: '+7',
		currency: 'KZT',
	},
	{ code: 'KE', name: 'Kenya', flag: '🇰🇪', dialCode: '+254', currency: 'KES' },
	{
		code: 'KI',
		name: 'Kiribati',
		flag: '🇰🇮',
		dialCode: '+686',
		currency: 'AUD',
	},
	{
		code: 'KP',
		name: 'Korea, North',
		flag: '🇰🇵',
		dialCode: '+850',
		currency: 'KPW',
	},
	{
		code: 'KR',
		name: 'Korea, South',
		flag: '🇰🇷',
		dialCode: '+82',
		currency: 'KRW',
	},
	{ code: 'KW', name: 'Kuwait', flag: '🇰🇼', dialCode: '+965', currency: 'KWD' },
	{
		code: 'KG',
		name: 'Kyrgyzstan',
		flag: '🇰🇬',
		dialCode: '+996',
		currency: 'KGS',
	},
	{ code: 'LA', name: 'Laos', flag: '🇱🇦', dialCode: '+856', currency: 'LAK' },
	{ code: 'LV', name: 'Latvia', flag: '🇱🇻', dialCode: '+371', currency: 'EUR' },
	{
		code: 'LB',
		name: 'Lebanon',
		flag: '🇱🇧',
		dialCode: '+961',
		currency: 'LBP',
	},
	{
		code: 'LS',
		name: 'Lesotho',
		flag: '🇱🇸',
		dialCode: '+266',
		currency: 'LSL',
	},
	{
		code: 'LR',
		name: 'Liberia',
		flag: '🇱🇷',
		dialCode: '+231',
		currency: 'LRD',
	},
	{ code: 'LY', name: 'Libya', flag: '🇱🇾', dialCode: '+218', currency: 'LYD' },
	{
		code: 'LI',
		name: 'Liechtenstein',
		flag: '🇱🇮',
		dialCode: '+423',
		currency: 'CHF',
	},
	{
		code: 'LT',
		name: 'Lithuania',
		flag: '🇱🇹',
		dialCode: '+370',
		currency: 'EUR',
	},
	{
		code: 'LU',
		name: 'Luxembourg',
		flag: '🇱🇺',
		dialCode: '+352',
		currency: 'EUR',
	},
	{ code: 'MO', name: 'Macao', flag: '🇲🇴', dialCode: '+853', currency: 'MOP' },
	{
		code: 'MK',
		name: 'Macedonia',
		flag: '🇲🇰',
		dialCode: '+389',
		currency: 'MKD',
	},
	{
		code: 'MG',
		name: 'Madagascar',
		flag: '🇲🇬',
		dialCode: '+261',
		currency: 'MGA',
	},
	{ code: 'MW', name: 'Malawi', flag: '🇲🇼', dialCode: '+265', currency: 'MWK' },
	{
		code: 'MY',
		name: 'Malaysia',
		flag: '🇲🇾',
		dialCode: '+60',
		currency: 'MYR',
	},
	{
		code: 'MV',
		name: 'Maldives',
		flag: '🇲🇻',
		dialCode: '+960',
		currency: 'MVR',
	},
	{ code: 'ML', name: 'Mali', flag: '🇲🇱', dialCode: '+223', currency: 'XOF' },
	{ code: 'MT', name: 'Malta', flag: '🇲🇹', dialCode: '+356', currency: 'EUR' },
	{
		code: 'MH',
		name: 'Marshall Islands',
		flag: '🇲🇭',
		dialCode: '+692',
		currency: 'USD',
	},
	{
		code: 'MQ',
		name: 'Martinique',
		flag: '🇲🇶',
		dialCode: '+596',
		currency: 'EUR',
	},
	{
		code: 'MR',
		name: 'Mauritania',
		flag: '🇲🇷',
		dialCode: '+222',
		currency: 'MRU',
	},
	{
		code: 'MU',
		name: 'Mauritius',
		flag: '🇲🇺',
		dialCode: '+230',
		currency: 'MUR',
	},
	{
		code: 'YT',
		name: 'Mayotte',
		flag: '🇾🇹',
		dialCode: '+262',
		currency: 'EUR',
	},
	{ code: 'MX', name: 'Mexico', flag: '🇲🇽', dialCode: '+52', currency: 'MXN' },
	{
		code: 'FM',
		name: 'Micronesia',
		flag: '🇫🇲',
		dialCode: '+691',
		currency: 'USD',
	},
	{
		code: 'MD',
		name: 'Moldova',
		flag: '🇲🇩',
		dialCode: '+373',
		currency: 'MDL',
	},
	{ code: 'MC', name: 'Monaco', flag: '🇲🇨', dialCode: '+377', currency: 'EUR' },
	{
		code: 'MN',
		name: 'Mongolia',
		flag: '🇲🇳',
		dialCode: '+976',
		currency: 'MNT',
	},
	{
		code: 'ME',
		name: 'Montenegro',
		flag: '🇲🇪',
		dialCode: '+382',
		currency: 'EUR',
	},
	{
		code: 'MS',
		name: 'Montserrat',
		flag: '🇲🇸',
		dialCode: '+1664',
		currency: 'XCD',
	},
	{
		code: 'MA',
		name: 'Morocco',
		flag: '🇲🇦',
		dialCode: '+212',
		currency: 'MAD',
	},
	{
		code: 'MZ',
		name: 'Mozambique',
		flag: '🇲🇿',
		dialCode: '+258',
		currency: 'MZN',
	},
	{ code: 'MM', name: 'Myanmar', flag: '🇲🇲', dialCode: '+95', currency: 'MMK' },
	{
		code: 'NA',
		name: 'Namibia',
		flag: '🇳🇦',
		dialCode: '+264',
		currency: 'NAD',
	},
	{ code: 'NR', name: 'Nauru', flag: '🇳🇷', dialCode: '+674', currency: 'AUD' },
	{ code: 'NP', name: 'Nepal', flag: '🇳🇵', dialCode: '+977', currency: 'NPR' },
	{
		code: 'NL',
		name: 'Netherlands',
		flag: '🇳🇱',
		dialCode: '+31',
		currency: 'EUR',
	},
	{
		code: 'NC',
		name: 'New Caledonia',
		flag: '🇳🇨',
		dialCode: '+687',
		currency: 'XPF',
	},
	{
		code: 'NZ',
		name: 'New Zealand',
		flag: '🇳🇿',
		dialCode: '+64',
		currency: 'NZD',
	},
	{
		code: 'NI',
		name: 'Nicaragua',
		flag: '🇳🇮',
		dialCode: '+505',
		currency: 'NIO',
	},
	{ code: 'NE', name: 'Niger', flag: '🇳🇪', dialCode: '+227', currency: 'XOF' },
	
	{ code: 'NU', name: 'Niue', flag: '🇳🇺', dialCode: '+683', currency: 'NZD' },
	{
		code: 'NF',
		name: 'Norfolk Island',
		flag: '🇳🇫',
		dialCode: '+672',
		currency: 'AUD',
	},
	{
		code: 'MP',
		name: 'Northern Mariana Islands',
		flag: '🇲🇵',
		dialCode: '+1670',
		currency: 'USD',
	},
	{ code: 'NO', name: 'Norway', flag: '🇳🇴', dialCode: '+47', currency: 'NOK' },
	{ code: 'OM', name: 'Oman', flag: '🇴🇲', dialCode: '+968', currency: 'OMR' },
	{
		code: 'PK',
		name: 'Pakistan',
		flag: '🇵🇰',
		dialCode: '+92',
		currency: 'PKR',
	},
	{ code: 'PW', name: 'Palau', flag: '🇵🇼', dialCode: '+680', currency: 'USD' },
	{
		code: 'PS',
		name: 'Palestine',
		flag: '🇵🇸',
		dialCode: '+970',
		currency: 'ILS',
	},
	{ code: 'PA', name: 'Panama', flag: '🇵🇦', dialCode: '+507', currency: 'PAB' },
	{
		code: 'PG',
		name: 'Papua New Guinea',
		flag: '🇵🇬',
		dialCode: '+675',
		currency: 'PGK',
	},
	{
		code: 'PY',
		name: 'Paraguay',
		flag: '🇵🇾',
		dialCode: '+595',
		currency: 'PYG',
	},
	{ code: 'PE', name: 'Peru', flag: '🇵🇪', dialCode: '+51', currency: 'PEN' },
	{
		code: 'PH',
		name: 'Philippines',
		flag: '🇵🇭',
		dialCode: '+63',
		currency: 'PHP',
	},
	{ code: 'PL', name: 'Poland', flag: '🇵🇱', dialCode: '+48', currency: 'PLN' },
	{
		code: 'PT',
		name: 'Portugal',
		flag: '🇵🇹',
		dialCode: '+351',
		currency: 'EUR',
	},
	{
		code: 'PR',
		name: 'Puerto Rico',
		flag: '🇵🇷',
		dialCode: '+1939',
		currency: 'USD',
	},
	{ code: 'QA', name: 'Qatar', flag: '🇶🇦', dialCode: '+974', currency: 'QAR' },
	{
		code: 'RE',
		name: 'Reunion',
		flag: '🇷🇪',
		dialCode: '+262',
		currency: 'EUR',
	},
	{ code: 'RO', name: 'Romania', flag: '🇷🇴', dialCode: '+40', currency: 'RON' },
	{ code: 'RU', name: 'Russia', flag: '🇷🇺', dialCode: '+7', currency: 'RUB' },
	{ code: 'RW', name: 'Rwanda', flag: '🇷🇼', dialCode: '+250', currency: 'RWF' },
	{
		code: 'BL',
		name: 'Saint Barthélemy',
		flag: '🇧🇱',
		dialCode: '+590',
		currency: 'EUR',
	},
	{ code: 'WS', name: 'Samoa', flag: '🇼🇸', dialCode: '+685', currency: 'WST' },
	{
		code: 'SM',
		name: 'San Marino',
		flag: '🇸🇲',
		dialCode: '+378',
		currency: 'EUR',
	},
	{
		code: 'ST',
		name: 'Sao Tome and Principe',
		flag: '🇸🇹',
		dialCode: '+239',
		currency: 'STN',
	},
	{
		code: 'SA',
		name: 'Saudi Arabia',
		flag: '🇸🇦',
		dialCode: '+966',
		currency: 'SAR',
	},
	{
		code: 'SN',
		name: 'Senegal',
		flag: '🇸🇳',
		dialCode: '+221',
		currency: 'XOF',
	},
	{ code: 'RS', name: 'Serbia', flag: '🇷🇸', dialCode: '+381', currency: 'RSD' },
	{
		code: 'SC',
		name: 'Seychelles',
		flag: '🇸🇨',
		dialCode: '+248',
		currency: 'SCR',
	},
	{
		code: 'SL',
		name: 'Sierra Leone',
		flag: '🇸🇱',
		dialCode: '+232',
		currency: 'SLL',
	},
	{
		code: 'SG',
		name: 'Singapore',
		flag: '🇸🇬',
		dialCode: '+65',
		currency: 'SGD',
	},
	{
		code: 'SK',
		name: 'Slovakia',
		flag: '🇸🇰',
		dialCode: '+421',
		currency: 'EUR',
	},
	{
		code: 'SI',
		name: 'Slovenia',
		flag: '🇸🇮',
		dialCode: '+386',
		currency: 'EUR',
	},
	{
		code: 'SB',
		name: 'Solomon Islands',
		flag: '🇸🇧',
		dialCode: '+677',
		currency: 'SBD',
	},
	{
		code: 'SO',
		name: 'Somalia',
		flag: '🇸🇴',
		dialCode: '+252',
		currency: 'SOS',
	},
	{
		code: 'ZA',
		name: 'South Africa',
		flag: '🇿🇦',
		dialCode: '+27',
		currency: 'ZAR',
	},
	{
		code: 'SS',
		name: 'South Sudan',
		flag: '🇸🇸',
		dialCode: '+211',
		currency: 'SSP',
	},
	{ code: 'ES', name: 'Spain', flag: '🇪🇸', dialCode: '+34', currency: 'EUR' },
	{
		code: 'LK',
		name: 'Sri Lanka',
		flag: '🇱🇰',
		dialCode: '+94',
		currency: 'LKR',
	},
	{ code: 'SD', name: 'Sudan', flag: '🇸🇩', dialCode: '+249', currency: 'SDG' },
	{
		code: 'SR',
		name: 'Suriname',
		flag: '🇸🇷',
		dialCode: '+597',
		currency: 'SRD',
	},
	{
		code: 'SZ',
		name: 'Swaziland',
		flag: '🇸🇿',
		dialCode: '+268',
		currency: 'SZL',
	},
	{ code: 'SE', name: 'Sweden', flag: '🇸🇪', dialCode: '+46', currency: 'SEK' },
	{
		code: 'CH',
		name: 'Switzerland',
		flag: '🇨🇭',
		dialCode: '+41',
		currency: 'CHF',
	},
	{ code: 'SY', name: 'Syria', flag: '🇸🇾', dialCode: '+963', currency: 'SYP' },
	{ code: 'TW', name: 'Taiwan', flag: '🇹🇼', dialCode: '+886', currency: 'TWD' },
	{
		code: 'TJ',
		name: 'Tajikistan',
		flag: '🇹🇯',
		dialCode: '+992',
		currency: 'TJS',
	},
	{
		code: 'TZ',
		name: 'Tanzania',
		flag: '🇹🇿',
		dialCode: '+255',
		currency: 'TZS',
	},
	{
		code: 'TH',
		name: 'Thailand',
		flag: '🇹🇭',
		dialCode: '+66',
		currency: 'THB',
	},
	{
		code: 'TL',
		name: 'Timor-Leste',
		flag: '🇹🇱',
		dialCode: '+670',
		currency: 'USD',
	},
	{ code: 'TG', name: 'Togo', flag: '🇹🇬', dialCode: '+228', currency: 'XOF' },
	{
		code: 'TK',
		name: 'Tokelau',
		flag: '🇹🇰',
		dialCode: '+690',
		currency: 'NZD',
	},
	{ code: 'TO', name: 'Tonga', flag: '🇹🇴', dialCode: '+676', currency: 'TOP' },
	{
		code: 'TT',
		name: 'Trinidad and Tobago',
		flag: '🇹🇹',
		dialCode: '+1868',
		currency: 'TTD',
	},
	{
		code: 'TN',
		name: 'Tunisia',
		flag: '🇹🇳',
		dialCode: '+216',
		currency: 'TND',
	},
	{ code: 'TR', name: 'Turkey', flag: '🇹🇷', dialCode: '+90', currency: 'TRY' },
	{
		code: 'TM',
		name: 'Turkmenistan',
		flag: '🇹🇲',
		dialCode: '+993',
		currency: 'TMT',
	},
	{
		code: 'TC',
		name: 'Turks and Caicos Islands',
		flag: '🇹🇨',
		dialCode: '+1649',
		currency: 'USD',
	},
	{ code: 'TV', name: 'Tuvalu', flag: '🇹🇻', dialCode: '+688', currency: 'AUD' },
	{ code: 'UG', name: 'Uganda', flag: '🇺🇬', dialCode: '+256', currency: 'UGX' },
	{
		code: 'UA',
		name: 'Ukraine',
		flag: '🇺🇦',
		dialCode: '+380',
		currency: 'UAH',
	},
	{
		code: 'AE',
		name: 'United Arab Emirates',
		flag: '🇦🇪',
		dialCode: '+971',
		currency: 'AED',
	},
	{
		code: 'GB',
		name: 'United Kingdom',
		flag: '🇬🇧',
		dialCode: '+44',
		currency: 'GBP',
	},
	{
		code: 'US',
		name: 'United States',
		flag: '🇺🇸',
		dialCode: '+1',
		currency: 'USD',
	},
	{
		code: 'UY',
		name: 'Uruguay',
		flag: '🇺🇾',
		dialCode: '+598',
		currency: 'UYU',
	},
	{
		code: 'UZ',
		name: 'Uzbekistan',
		flag: '🇺🇿',
		dialCode: '+998',
		currency: 'UZS',
	},
	{
		code: 'VU',
		name: 'Vanuatu',
		flag: '🇻🇺',
		dialCode: '+678',
		currency: 'VUV',
	},
	{
		code: 'VA',
		name: 'Vatican City',
		flag: '🇻🇦',
		dialCode: '+379',
		currency: 'EUR',
	},
	{
		code: 'VE',
		name: 'Venezuela',
		flag: '🇻🇪',
		dialCode: '+58',
		currency: 'VES',
	},
	{ code: 'VN', name: 'Vietnam', flag: '🇻🇳', dialCode: '+84', currency: 'VND' },
	{
		code: 'WF',
		name: 'Wallis and Futuna',
		flag: '🇼🇫',
		dialCode: '+681',
		currency: 'XPF',
	},
	{ code: 'YE', name: 'Yemen', flag: '🇾🇪', dialCode: '+967', currency: 'YER' },
	{ code: 'ZM', name: 'Zambia', flag: '🇿🇲', dialCode: '+260', currency: 'ZMW' },
	{
		code: 'ZW',
		name: 'Zimbabwe',
		flag: '🇿🇼',
		dialCode: '+263',
		currency: 'ZWL',
	},
];
