import React, { useEffect, useState } from 'react';
import SideModal from './SideModal';
import Icon from '../Icon';
import { dateFormat } from '../../utils/helpers';
import {
	getResellablePass,
	resellerClientById,
	updateResellerDate,
	updateResellerPass,
	editClientsDetails,
	getAllRessellersClients,
	deleteResellerClient,
} from '../../features/resellers/resellersSlice';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../forms/Input';
import Spinner from '../PageLoader';
import EditClientDetails from '../EditClientDetails';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ClientsDetailSideModal = ({
	openSideModal,
	setOpenSideModal,
	selectedClient,
}) => {
	const dispatch = useDispatch();
	const [openPassList, setOpenPassList] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState({});
	const [changeToDateInputField, setChangeToDateInputField] = useState(false);
	const [changeDate, setChangeDate] = useState('');
	const [editClient, setEditClient] = useState(false);
	const [phoneDetails, setPhoneDetails] = useState({});

	useEffect(() => {
		dispatch(getResellablePass());
		dispatch(resellerClientById(selectedClient?.id));
	}, []);
	const { resellerPasses, individualClients, loadSideModal } = useSelector(
		(store) => store.resellers
	);
	const updateUerPass = (user) => {
		dispatch(
			updateResellerPass({
				resellerClientId: selectedClient?.id,
				flancePassId: user?.id,
			})
		).then((res) => {
			if (res?.type === 'pass/updateResellerPass/fulfilled') {
				dispatch(resellerClientById(selectedClient?.id));
			}
		});
	};
	const saveExpDate = () => {
		dispatch(
			updateResellerDate({
				resellerClientId: selectedClient?.id,
				expiryDate: changeDate,
			})
		).then((res) => {
			if (res?.type === 'pass/updateResellerDate/fulfilled') {
				dispatch(resellerClientById(selectedClient?.id));
				setChangeToDateInputField(false);
			}
		});
	};
	const schema = Yup.object({
		email: Yup.string().email('Invalid email pattern'),
	});

	const {
		handleChange,
		handleBlur,
		values,
		setFieldValue,
		handleSubmit,
		errors,
		touched,
	} = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',

			clientId: '',
		},
		validationSchema: schema,
		onSubmit: (values) => {
			const { firstName, lastName, email, clientId } = values;
			dispatch(
				editClientsDetails({
					clientId,
					email,
					phone: phoneDetails?.phoneNumber,
					firstName,
					lastName,
					id: individualClients?.data?.id,
				})
			).then((res) => {
				if (res?.type === 'pass/editClientsDetails/fulfilled') {
					dispatch(getAllRessellersClients({ page: 0, numPerPage: 25 }));
					dispatch(resellerClientById(selectedClient?.id));
					setEditClient(false);
				}
			});
		},
	});

	return (
		<SideModal
			headerText={`CLIENT DETAILS `}
			openSideModal={openSideModal}
			setOpenSideModal={setOpenSideModal}
			btnText={editClient ? 'Save Changes' : 'Edit Client'}
			isCart={false}
			handleClick={() => {
				if (editClient) {
					handleSubmit();
				} else {
					setEditClient(true);
				}
			}}
			isSecondBtn="Delete Client"
			handleSecondBtnClick={() =>
				dispatch(deleteResellerClient(individualClients?.data?.id)).then(
					(res) => {
						if (res?.type === 'pass/deleteResellerClient/fulfilled') {
							dispatch(getAllRessellersClients({ page: 0, numPerPage: 25 }));
							setOpenSideModal(false);
						}
					}
				)
			}
		>
			{loadSideModal ? (
				<Spinner />
			) : (
				<div className="clients__details">
					{editClient ? (
						<>
							<EditClientDetails
								// handleSubmit={handleSubmit}
								setFieldValue={setFieldValue}
								handleChange={handleChange}
								values={values}
								handleBlur={handleBlur}
								individualClients={individualClients}
								errors={errors}
								touched={touched}
								setPhoneDetails={setPhoneDetails}
							/>
						</>
					) : (
						<>
							<div className="clients__details--deets">
								<div className="deet">
									<p>
										<Icon id={'profile'} height={'16'} width={'16'} />
										Full Name
									</p>
									<h4>
										{individualClients?.data?.firstName +
											' ' +
											individualClients?.data?.lastName}
									</h4>
								</div>
								<div className="deet">
									<p>
										<Icon id={'profile-check'} height={'16'} width={'16'} />
										Client ID
									</p>
									<h4>
										{individualClients?.data?.clientId
											? individualClients?.data?.clientId
											: '--'}
									</h4>
								</div>
							</div>
							<div className="clients__details--deets">
								<div className="deet">
									<p>
										<Icon id={'phone'} height={'16'} width={'16'} />
										PHONE NUMBER
									</p>
									<h4>
										{individualClients?.data?.phone
											? individualClients?.data?.phone
											: '--'}
									</h4>
								</div>
								<div className="deet">
									<p>
										<Icon id={'mail'} height={'16'} width={'16'} />
										Email
									</p>
									<h4>
										{individualClients?.data?.email
											? individualClients?.data?.email
											: '--'}
									</h4>
								</div>
							</div>
							<div className="clients__details--deets">
								<div className="deet">
									<p>
										<Icon id={'threeDots'} height={'16'} width={'16'} />
										Passcode
									</p>
									<h4>{individualClients?.data?.accessCode}</h4>
								</div>
							</div>

							<div className="clients__details--pass">
								<div className="pass__deets">
									<div className="deets--side">
										<h4>
											{' '}
											<Icon id={'passTag'} height={'16'} width={'16'} />
											Pass Type
										</h4>
										<p>{individualClients?.data?.passName}</p>
									</div>

									<div className="deets--btn">
										<div
											className="__filterContainer"
											onMouseLeave={() => setOpenPassList(false)}
										>
											<div
												className="--filter"
												onMouseOver={() => setOpenPassList(true)}
											>
												<p>
													{selectedFilter?.name
														? selectedFilter?.name
														: 'Update Pass'}
												</p>
												<Icon id="whiteCaretDown" width="16" height="16" />
											</div>
											{openPassList && (
												<div className="filterList">
													<ul>
														{resellerPasses?.data?.map((item, index) => (
															<li
																onClick={() => {
																	setSelectedFilter(item);
																	updateUerPass(item);
																}}
																key={index}
															>
																{item?.name}
															</li>
														))}
													</ul>
												</div>
											)}
										</div>
									</div>
								</div>
								<hr />
								<div className="pass__deets">
									<div className="deets--side">
										<h4>
											<Icon id={'blue-cal'} height={'16'} width={'16'} />
											Exp Date
										</h4>
										<p>
											{dateFormat(
												individualClients?.data?.expiryDate,
												'MMM, dd yyyy'
											)}
										</p>
									</div>
									{changeToDateInputField && (
										<Input
											type="date"
											id="expiryDate"
											isFloatingLabel={false}
											value={changeDate}
											onChange={(e) => setChangeDate(e.target.value)}
											// onBlur={(e) => setChangeDate(e.target.value)}
											size={'sm'}
										/>
									)}
									{changeToDateInputField ? (
										<div className="deets--date" onClick={saveExpDate}>
											<p>Save Exp Date</p>
										</div>
									) : (
										<div
											className="deets--date"
											onClick={() => setChangeToDateInputField(true)}
										>
											<p>Update Exp Date</p>
										</div>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</SideModal>
	);
};

export default ClientsDetailSideModal;
