import React, { useEffect, useRef, useState } from 'react';
import Icon from '../Icon';
import { countries } from '../../utils/country';
import parsePhoneNumberFromString, {
	isValidPhoneNumber,
} from 'libphonenumber-js';
const PhoneInput = ({ setPhoneDetails }) => {
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [error, setError] = useState(null);
	const [formattedNumber, setFormattedNumber] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const dropdownRef = useRef(null);

	useEffect(() => {
		if (!error && phoneNumber) {
			setPhoneDetails({ phoneNumber: phoneNumber, country: selectedCountry });
		} else {
			setPhoneDetails({});
		}
	}, [phoneNumber, error, selectedCountry, setPhoneDetails]);

	const filteredCountries = countries.filter(
		(country) =>
			country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			country.dialCode.includes(searchQuery) ||
			country.currency.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const formatPhoneNumber = (value, country) => {
		try {
			const phoneNumber = parsePhoneNumberFromString(value, country.code);
			if (phoneNumber) {
				return phoneNumber.formatNational();
			}
		} catch (error) {
			return value;
		}
		return value;
	};

	const validatePhoneNumber = (value, country) => {
		try {
			const fullNumber = `${country.dialCode}${value.replace(/\D/g, '')}`;
			if (!value) {
				setError(null);
				return;
			}
			if (!isValidPhoneNumber(fullNumber, country.code)) {
				setError('Please enter a valid phone number');
			} else {
				setError(null);
			}
		} catch (error) {
			setError('Invalid phone number format');
		}
	};

	const handlePhoneNumberChange = (e) => {
		const value = e.target.value.replace(/\D/g, '');
		setPhoneNumber(value);
		const formatted = formatPhoneNumber(value, selectedCountry);
		setFormattedNumber(formatted);
		validatePhoneNumber(value, selectedCountry);
	};

	const handleCountrySelect = (country) => {
		setSelectedCountry(country);
		setIsOpen(false);
		setSearchQuery('');
		validatePhoneNumber(phoneNumber, country);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
				setSearchQuery('');
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	useEffect(() => {
		if (phoneNumber) {
			const formatted = formatPhoneNumber(phoneNumber, selectedCountry);
			setFormattedNumber(formatted);
		}
	}, [phoneNumber, selectedCountry]);

	return (
		<div className="phone-input-container">
			{/* <label htmlFor="phone-input" className="phone-input-label">
      Phone number
    </label> */}
			<div className="phone-input-wrapper">
				<div className="country-selector-wrapper" ref={dropdownRef}>
					<button
						type="button"
						className="country-selector"
						onClick={() => setIsOpen(!isOpen)}
						aria-label="Select country"
					>
						<span className="country-flag">{selectedCountry.flag}</span>
						<span className="country-dial-code">
							{selectedCountry.dialCode}
						</span>
						<Icon id="dropdown" width="16" height="11" />
					</button>

					{isOpen && (
						<div className="dropdown">
							<div className="dropdown-search">
								<div className="input--container">
									<Icon id="search-input" width={16} height={16} />
									<input
										type="text"
										className="dropdown-search-input"
										placeholder="Search countries..."
										value={searchQuery}
										onChange={(e) => setSearchQuery(e.target.value)}
									/>
								</div>
							</div>
							<div className="dropdown-list">
								{filteredCountries.map((country) => (
									<button
										key={country.code}
										className="country-option"
										onClick={() => handleCountrySelect(country)}
									>
										<div>
											<span className="country-flag">{country.flag}</span>
											<span className="country-name">{country.name}</span>
										</div>
										<span className="country-dial-code">
											{country.dialCode}
										</span>
									</button>
								))}
							</div>
						</div>
					)}
				</div>

				<input
					id="phone-input"
					type="tel"
					value={formattedNumber}
					onChange={handlePhoneNumberChange}
					className="phone-input"
					placeholder="080 0000 0000"
					aria-invalid={error ? 'true' : 'false'}
					aria-describedby={error ? 'phone-error' : undefined}
				/>
			</div>

			{error && (
				<p className="error-message" id="phone-error">
					{error}
				</p>
			)}
		</div>
	);
};

export default PhoneInput;
