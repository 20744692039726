import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
import employeesSlice from './features/employees/employeesSlice';
// import PlanSlice from './features/Plan/PlanSlice';
import planSlice from './features/plan/planSlice';
import walletSlice from './features/wallet/walletSlice';
import resellersSlice from './features/resellers/resellersSlice';
import countrySlice from './features/country/countrySlice';

export const store = configureStore({
	reducer: {
		user: userSlice,
		employees: employeesSlice,
		plan: planSlice,
		wallet: walletSlice,
		resellers: resellersSlice,
		country: countrySlice,
	},
});
