import React, { useEffect, useState } from 'react';
import Goback from '../../../components/Goback';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import SearchAddContainer from '../../../components/SearchAddContainer';
import ClientsTable from '../../../components/table/ClientsTable';
import ClientsDetailSideModal from '../../../components/modals/ClientsDetailSideModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAllRessellersClients,
	getResellablePass,
} from '../../../features/resellers/resellersSlice';
import Spinner from '../../../components/PageLoader';
import PaginationWithApi from '../../../components/PaginationWithApi';

const Clients = () => {
	const navigate = useNavigate();
	const [userSearch, setUserSearch] = useState('');
	const [searchInput, setSearchInput] = useState('');
	const [openSideModal, setOpenSideModal] = useState(false);
	const [selectedClient, setSelectedClient] = useState({});
	const [selectedFilter, setSelectedFilter] = useState({});
	const [currentPage, setCurrentPage] = useState(0); // Current page
	const { resellersClients, submitingFrom, resellerPasses } = useSelector(
		(store) => store.resellers
	);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getResellablePass());
	}, []);

	const handleNext = () => {
		if (!resellersClients?.last) {
			const nextPage = currentPage + 1;
			setCurrentPage(nextPage);
		}
	};

	const handlePrev = () => {
		if (currentPage > 0) {
			const prevPage = currentPage - 1;
			setCurrentPage(prevPage);
		}
	};

	const handleClearFilter = () => {
		setSearchInput('');
		setUserSearch('');
		setSelectedFilter({});
		setCurrentPage(0);
	};

	useEffect(() => {
		if (selectedFilter?.id || searchInput) {
			setCurrentPage(0);
		}
	}, [selectedFilter?.id, searchInput]);

	useEffect(() => {
		dispatch(
			getAllRessellersClients({
				page: currentPage,
				numPerPage: 25,
				id: selectedFilter?.id === 'all' ? '' : selectedFilter?.id,
				search: searchInput,
			})
		);
	}, [currentPage, selectedFilter?.id, searchInput]);

	return (
		<div className="clients">
			<Goback linkTo={'/enterprise'} />
			<div className="clients__header header--btn">
				<div>
					<h4>Clients</h4>
					<p className="mini-text">
						Manage your client list and assign vouchers
					</p>
				</div>
				<div className="enterprise__header--btn">
					<Button
						variant={'primary'}
						size={'md'}
						onClick={() => {
							navigate('/add-clients');
						}}
					>
						<span className="add">+</span>
						Add New Clients
					</Button>
				</div>
			</div>
			<div className="clients__table mt-16">
				<div className="clients__table--header">
					<h4>Client Information</h4>
					<SearchAddContainer
						changeInput={(e) => setUserSearch(e.target.value)}
						filterOptions={resellerPasses?.data?.map((item) => {
							return { name: item?.name, id: item?.id };
						})}
						searchInput={userSearch}
						selectedFilter={selectedFilter}
						setSelectedFilter={setSelectedFilter}
						flipSides={true}
						isFilterObject={true}
						addAllfilter={true}
						btnSearch={true}
						handleSearch={() => setSearchInput(userSearch)}
						placeHolderText={'Search Client by Name or Client ID'}
					/>
				</div>
				<div className="clients__table--body mt-24">
					{submitingFrom ? (
						<Spinner />
					) : (
						<ClientsTable
							setOpenSideModal={setOpenSideModal}
							data={resellersClients?.content}
							setSelectedClient={setSelectedClient}
							handleClearFilter={handleClearFilter}
						/>
					)}
					{resellersClients?.content?.length > 0 && (
						<PaginationWithApi
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							data={resellersClients}
							handleNext={handleNext}
							handlePrev={handlePrev}
						/>
					)}
				</div>
			</div>
			{openSideModal && (
				<ClientsDetailSideModal
					openSideModal={openSideModal}
					setOpenSideModal={setOpenSideModal}
					selectedClient={selectedClient}
				/>
			)}
		</div>
	);
};

export default Clients;
