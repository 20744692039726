import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const initialState = {
	isLoading: false,
	countries: [],
};

const countryFetch = axios.create({
	baseURL: 'https://restcountries.com/v3.1/',
});
export const getCountryData = createAsyncThunk(
	'country/countryCode',
	async (_, thunkAPI) => {
		try {
			const resp = await countryFetch.get(
				'all?fields=flags,cca2,idd,currencies'
			);
			return resp?.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response?.data);
		}
	}
);

const countrySlice = createSlice({
	name: 'country',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCountryData.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getCountryData.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.countries = payload;
			})
			.addCase(getCountryData.rejected, (state) => {
				state.isLoading = false;
			});
	},
});

export default countrySlice.reducer;
